import React, { FC, useCallback } from "react";
import LazyLoad from "react-lazyload";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollToSection } from "../../../helpers/functions";

type Props = {
    title?: string;
    showBtn?: boolean;
    video?: string;
    video480?: string;
};

const TopBanner: FC<Props> = ({ title = "", showBtn = false, video, video480 }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = useCallback(
        (id: string) => {
            const path = scrollToSection(id, location);
            if (path) {
                navigate(path);
            }
        },
        [navigate, location]
    );

    return (
        <div className="container-fluid px-0 top-banner overflow-hidden">
            {video ? (
                <div className="video-wrapper">
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="video w-100"
                        poster="/assets/poster.jpg"
                        preload="metadata"
                    >
                        <source src={video} type="video/mp4" media="(min-width: 720px)"/>
                        {
                            video480 && (<source src={video480} type="video/mp4"/>)
                        }
                    </video>
                </div>
            ) : (
                <LazyLoad height={400} offset={100}>
                    <div className="banner-image w-100"/>
                </LazyLoad>
            )}

            <div className="block-center">
                <div
                    className="pt-5 text-center"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                {showBtn && (
                    <div className="text-center">
                        <button
                            className="btn btn-black"
                            onClick={() => handleNavigation("resale")}
                        >
                            SEARCH FOR PROPERTIES
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopBanner;
