import React, {useEffect, useState} from "react";
import callImage from "assets/images/call-image.png";
import callOverlay from "assets/images/call-overlay.png";
import {useFormik} from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import LazyLoad from "react-lazyload";
import Select from "react-select";
import {contact} from "../../core/home/requests";
import {useHeaderContext} from "../../layout/context/HeaderContext";
import {SocialMeta} from "../../components/SocialMeta";


const initialValues = {
    email: "",
    name: "",
    message: "",
    type: 6,
    subject: ""
};

const contactSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),
    name: Yup.string().required("Name is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
});


const Contact = () => {


    const subjects = [
        {

            label: "Interested in properties for sale",
            value: "properties-sale",

        },
        {

            label: "Interested in properties for rent",
            value: "properties-rent",

        },
        {

            label: "Interested in OffPlan Properties only",
            value: "offplan",

        },
        {

            label: "Notify me of upcoming events",
            value: "events",

        },
        {

            label: "Interested in listing my property with Olive Branch Dubai",
            value: "listing-property-with-ob",

        },
        {

            label: "Other",
            value: "other",

        }
    ]

    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);
            try {
                setLoading(true);
                //
                const {data} = await contact(values);
                if (data === 1) {
                    setLoading(false);
                    setIsActive(true);
                    formik.resetForm();
                    setTimeout(function () {
                        setIsActive(false);
                        // resetForm()
                    }, 3500);
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    const [selectedSubject, setSelectedSubject] = useState<any>("");

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const subjectValue = searchParams.get('subject');

        // Find the corresponding subject object
        const selectedSubject = subjects.find(subject => subject.value === subjectValue);


        if (selectedSubject) {
            // If a corresponding subject object is found, set it as the default value for the Select component
            formik.setValues({
                ...formik.values,
                subject: selectedSubject.value
            });
            setSelectedSubject(selectedSubject || initialValues.subject);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const {setVariant, setLogoColor} = useHeaderContext();
    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("property");
        setLogoColor("dark")
        return () => {
            // Clean up or reset when component unmounts
            setVariant("property");
            setLogoColor("dark");
        };
    }, [setVariant, setLogoColor]);
    return (
        <>
            <SocialMeta
                title={'Contact Us - Get in Touch Today'}
                description={'Have questions or need assistance? Reach out to Olive Branch through our Contact page. We\'re here to help with any inquiries, support, or feedback.'}/>

            <section id="contact-section" className="border-bottom">
                <div className=" container">

                    <div className="row align-items-center mb-5  position-relative">
                        <div className="col-lg-6 col-md-12   mt-2">
                            <img src={callOverlay} alt="Olive Branch" className="overlay-left d-lg-block d-none"/>
                            <LazyLoad offset={100}
                                      placeholder={<span
                                          className="spinner-border spinner-border-sm align-middle ms-2 text-dark"></span>}>

                                <img
                                    src={callImage}
                                    alt="Olive Branch"
                                    className="w-100 bdr-left-lg"
                                />
                            </LazyLoad>
                        </div>
                        <div className="col-lg-6 col-md-12  mt-2">
                                <h1 className="fs-40 text-black">Contact Us</h1>
                                <div className="row mt-3 w-100">
                                    <div className={'col-md-12'}>
                                        <div className={` ${isActive ? " alert-box d-block" : "alert-box d-none"}`}>
                                            <div className="alert alert-success alert-dismissible fade show"
                                                 role="alert">
                                                Successfully Done
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <form className="z-index-999"
                                      onSubmit={formik.handleSubmit}
                                      noValidate>
                                    <div className="row mt-1">
                                        <div className="col-md-12">
                                            <label className="form-label">Name</label>
                                            <input type="text"  {...formik.getFieldProps("name")}
                                                   className={clsx(
                                                       "form-control",
                                                       {
                                                           "is-invalid":
                                                               formik.touched.name && formik.errors.name,
                                                       },
                                                       {
                                                           "is-valid":
                                                               formik.touched.name && !formik.errors.name,
                                                       })} name="name"/>
                                        </div>
                                        {/*{formik.touched.name && formik.errors.name && (*/}
                                        {/*    <div className="fv-plugins-message-container">*/}
                                        {/*        <span role="alert">{formik.errors.name}</span>*/}
                                        {/*    </div>*/}
                                        {/*)}*/}
                                        <div className="col-md-12 mt-3">
                                            <label className="form-label">Email</label>
                                            <input type="email"

                                                   {...formik.getFieldProps("email")}
                                                   className={clsx(
                                                       "form-control",
                                                       {
                                                           "is-invalid":
                                                               formik.touched.email && formik.errors.email,
                                                       },
                                                       {
                                                           "is-valid":
                                                               formik.touched.email && !formik.errors.email,
                                                       })} name="email"/>
                                        </div>

                                        {/*{formik.touched.email && formik.errors.email && (*/}
                                        {/*    <div className="fv-plugins-message-container">*/}
                                        {/*        <span role="alert">{formik.errors.email}</span>*/}
                                        {/*    </div>*/}
                                        {/*)}*/}


                                        {subjects && subjects.length > 0 &&

                                            <div className={'col-md-12 mt-3'}>
                                                <label className="form-label">Subject</label>
                                                <Select

                                                    className={clsx("h-none w-100",
                                                        {
                                                            "invalid-select":
                                                                formik.touched.subject && formik.errors.subject
                                                        },
                                                        {
                                                            "valid-select":
                                                                formik.touched.subject && !formik.errors.subject
                                                        },
                                                    )}
                                                    closeMenuOnSelect={true}
                                                    placeholder={"Select Subject"}
                                                    options={subjects.map((e: {
                                                        label: any;
                                                        value: any
                                                    }) => ({label: e.label, value: e.value}))}
                                                    isSearchable={true}
                                                    onChange={(selectedOption: any) => {
                                                        formik.setFieldValue('subject', selectedOption.value);
                                                        setSelectedSubject(selectedOption.value);
                                                    }}
                                                    value={subjects.find(subject => subject.value === selectedSubject.value)}


                                                />
                                                {/*{formik.touched.subject && formik.errors.subject && (*/}
                                                {/*    <div className="fv-plugins-message-container">*/}
                                                {/*        <span role="alert">{formik.errors.subject}</span>*/}
                                                {/*    </div>*/}
                                                {/*)}*/}
                                            </div>
                                        }


                                        <div className="col-md-12 mt-3">
                                            <label className="form-label">Message</label>
                                            <textarea    {...formik.getFieldProps("message")}
                                                         className={clsx(
                                                             "form-control h-150",
                                                             {
                                                                 "is-invalid":
                                                                     formik.touched.message && formik.errors.message,
                                                             },
                                                             {
                                                                 "is-valid":
                                                                     formik.touched.message && !formik.errors.message,
                                                             })} name="message"/>
                                        </div>

                                        {formik.touched.message && formik.errors.message && (
                                            <div className="fv-plugins-message-container">
                                                <span role="alert">{formik.errors.message}</span>
                                            </div>
                                        )}

                                        <div className="col-lg-4 col-6 mt-3">

                                            <button className="btn btn-black p-40 w-100"
                                                    disabled={formik.isSubmitting}
                                                    type="submit">{!loading && (
                                                <span className="indicator-label">
                                  Submit
                                </span>
                                            )}
                                                {loading && (
                                                    <span
                                                        className="indicator-progress"
                                                        style={{display: "block"}}
                                                    >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                                )}</button>
                                        </div>

                                    </div>

                                </form>


                        </div>
                    </div>
                </div>
            </section>

        </>

    );
};

export default Contact;
