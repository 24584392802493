import React, {useEffect} from "react";
import {SocialMeta} from "../../components/SocialMeta";
import {useHeaderContext} from "../../layout/context/HeaderContext";
import TopBanner from "../home/components/TopBanner";
import {Link} from "react-router-dom";


const Privacy: React.FC = () => {
    const {setVariant, setLogoColor} = useHeaderContext();

    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("property");
        setLogoColor("dark");
        return () => {
            // Clean up or reset when component unmounts
            setVariant("property");
            setLogoColor("dark");
        };
    }, [setVariant, setLogoColor]);

    return (
        <>
            <SocialMeta
                title={'Privacy Policy'}
                description={'Privacy policy for Olive Branch Properties, real estate specialists in Dubai. Learn about how we collect, use, and protect your personal information.'}
                keyword={'privacy policy, Olive Branch Properties, real estate, Dubai'}
            />

            <TopBanner
                title={'<h1 class="title-2 fs-65 mb-0">Privacy Policy</h1>'}
            />

            <section id="privacy-section" className="privacy-section border-bottom">
                <div className="container mt-5 pt-3">
                    <div className="row justify-content-center">
                        <div className="col-md-10 mb-5">
                            <div className="dark-light-color fs-16 lh-25 f-main-medium ">
                                <div>
                                    At Olive Branch Properties, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website <Link to={'https://olivebranchdubai.com/'}>https://olivebranchdubai.com/</Link>. Please read this privacy policy carefully.


                            </div>
                            <div className={'mt-3'}>
                               <h3 >Information Collection</h3>
                               We may collect personal information that you voluntarily provide to us when you
                                express an interest in obtaining information about our services or engage with us in any
                                other way. The personal information we collect may include your name, email address,
                                phone number, and any other information you choose to provide.
                            </div>
                                <div className={'mt-3'}>
                                    <h3> Information Usage</h3>
                                       We use the information we collect to provide, maintain, and improve our
                                         services,
                                         as
                                         well as to communicate with you. We may use your information to:
                                        <ul>
                                            <li> Respond to your inquiries and fulfill your requests</li>
                                            <li> Send you relevant information about our services</li>
                                            <li> Perform analytics to understand how our website is used and how we can improve
                                                 it</li>
                                        </ul>


                                </div>
                                <div className={'mt-3'}>
                                    <h3> Information Sharing</h3>
                                        We do not sell, trade, or otherwise transfer your personal information to
                                         outside
                                         parties. However, we may share generic aggregated demographic information not
                                         linked
                                         to
                                         any personal identification information with our business partners or
                                         advertisers.
                                </div>
                                <div className={'mt-3'}>
                                    <h3> Security</h3>
                                        We take precautions to protect your information. When you submit sensitive
                                         information
                                         via the website, your information is protected both online and offline.
                                         However, no
                                         method of transmission over the Internet or electronic storage is 100% secure,
                                         so we
                                         cannot guarantee its absolute security.
                                </div>
                                <div className={'mt-3'}>
                                    <h3> Changes to this Privacy Policy</h3>
                                      We reserve the right to update or change our Privacy Policy at any time.
                                         You
                                         should
                                         check this Privacy Policy periodically. Your continued use of our website after
                                         we
                                         post
                                         any modifications to the Privacy Policy on this page will constitute your
                                         acknowledgment
                                         of the modifications.
                                </div>
                                <div className={'mt-3'}>
                                    <h3> Contact Us</h3>
                                        If you have any questions about this Privacy Policy, please contact us at
                                    <Link to={'mailto:info@olivebranchdubai.com'} className={'text-decoration-underline'}> info@olivebranchdubai.com</Link>
                                </div>


                            </div>
                            </div>
                        </div>
                    </div>



            </section>
        </>
    );
};

export default Privacy;
