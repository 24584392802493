import React, {FC} from "react";
import {toAbsoluteUrl} from "../../helpers";
import {Link} from "react-router-dom";
import {PROPERTY_STATUS} from "../../helpers/crud-helper/consts";

type Props = {
    item?: any
    images?: any
}

const ResaleCard: FC<Props> = ({item, images}) => {

    return (
        <>

            {item.beds > 0 && item.bathrooms > 0 ?
                <div
                    className={' card card-custom gutter-b example example-compact card-stretch property-block'}>
                    <Link to={`/property/${item.slug}`}>
                        {images && images.length > 0 ?
                            <img
                                src={`${toAbsoluteUrl(images[0].image)}`}
                                alt="Resale Card Image"
                                className="w-100 h-300px"
                            />
                            :
                            item.image &&
                            <img
                                src={`${toAbsoluteUrl(item.image.image)}`}
                                alt="Resale Card Image"
                                className="w-100 h-300px"
                            />
                        }
                    </Link>

                    <div className="m-4 text-start">
                        <Link to={`/property/${item.slug}`}>
                            <div className={'h-250px'}>
                                <div className={'d-flex justify-content-between'}>
                                    <div className={'text-sm-grey'}>
                                        {item.property.name}
                                    </div>

                                    {item.status && item.status !== "2" &&
                                        <div className={'project-status'}>{PROPERTY_STATUS[item.status]}</div>}
                                </div>

                                <div className={'mt-2 title-sm fs-17'}>
                                    {item.name}
                                </div>

                                <ul className="property-list mt-2 mb-2">
                                    <li>{item.beds} <span>Bedrooms</span></li>
                                    <li>{item.bathrooms} <span>Baths</span></li>
                                    <li>{item.size}</li>
                                </ul>

                                <div className={'d-flex justify-content-between'}>
                                    <div className={'text-brown-main'}>
                                        {item.price} {item.currency.name}

                                    </div>

                                </div>
                                {item.location &&
                                    <div className={'d-flex align-items-center sm-nb mt-4'}>
                                        <div>
                                            <img width={'23'} className={'me-1'}
                                                 src={'/assets/images/icons/location.svg'}
                                                 alt={'Olive Branch Icon'}/>
                                        </div>
                                        <div className={'location'}>
                                            {item.location}
                                        </div>

                                    </div>}
                            </div>
                        </Link>

                        <div className={' d-flex justify-content-between mt-4'}>
                            <div className="d-flex">
                                <div className={'me-2 icons-n'}>
                                    <a href={`whatsapp://send?phone=${item.phone_number}&text=Hello, I would like to get more information about this property on the website, Name: ${item.name} Type: ${item.property.name} Price: ${item.price} ${item.currency.name} Location:  ${item.location}. `}>
                                        <i className="fab fa-whatsapp"></i>
                                    </a>

                                </div>

                                <div className={'icons-n  me-2'}>
                                    <a href={`tel:${item.phone_number}`}> <i
                                        className="fas fa-phone-volume"></i> </a>
                                </div>

                                <div className={' icons-n'}>
                                    <a target="_blank" rel="noreferrer"
                                       href={`mailto:${item.email}`}> <i
                                        className="fa fa-envelope"></i>
                                    </a>
                                </div>

                            </div>
                            <div className={'mt-1'}>
                                <Link to={`/property/${item.slug}`} className={'link-dark'}>LEARN MORE</Link>
                            </div>
                        </div>
                    </div>
                </div>

                :

                <Link to={`/property/${item.slug}`}>
                    <div className="imageBox  pb-5">
                        {images && images.length > 0 ?
                            <img
                                src={`${toAbsoluteUrl(images[0].image)}`}
                                alt="Resale Card Image"
                                className="w-100 h-300px"
                            />
                            :
                            item.image &&
                            <img
                                src={`${toAbsoluteUrl(item.image.image)}`}
                                alt="Resale Card Image"
                                className="w-100 h-300px"
                            />
                        }
                    </div>
                </Link>
            }
        </>
    );
};

export default ResaleCard;
