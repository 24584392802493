import React, {useEffect, useState} from "react";
import clsx from "clsx";
import Select from "react-select";
import * as Yup from "yup";
import {useFormik} from "formik";
import {contact} from "../../../core/home/requests";

const initialValues = {
    email: "",
    name: "",
    message: "",
    type: 5,
    subject: ""
};

const subjects = [
    {

        label: "Interested in properties for sale",
        value: "properties-sale",

    },
    {

        label: "Interested in properties for rent",
        value: "properties-rent",

    },
    {

        label: "Interested in OffPlan Properties only",
        value: "offplan",

    },
    {

        label: "Notify me of upcoming events",
        value: "events",

    },
    {

        label: "Interested in listing my property with Olive Branch Dubai",
        value: "listing-property-with-ob",

    },
    {

        label: "Other",
        value: "other",

    }
]

const contactSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),
    name: Yup.string().required("Name is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
});

const Contact: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState<any>("");

    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);
            try {
                setLoading(true);
                //
                const {data} = await contact(values);
                if (data === 1) {
                    setLoading(false);
                    setIsActive(true);
                    formik.resetForm();
                    setTimeout(function () {
                        setIsActive(false);
                        // resetForm()
                    }, 3500);
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const subjectValue = searchParams.get('subject');

        // Find the corresponding subject object
        const selectedSubject = subjects.find(subject => subject.value === subjectValue);


        if (selectedSubject) {
            // If a corresponding subject object is found, set it as the default value for the Select component
            formik.setValues({
                ...formik.values,
                subject: selectedSubject.value
            });
            setSelectedSubject(selectedSubject || initialValues.subject);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container py-80">
            <div className="col-xl-10 col-lg-10 col-lg-8 col-sm-12 col-12 col-centered">
                <div className="row d-flex align-items-center">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-12 w-mb-sm-25px">
                        <h2 className="fs-40 text-black">Contact Us</h2>
                        <div className="contact-info">
                            <div className="contact-item">
                                <i className="fas fa-phone-volume"></i>
                                <a href="tel:+97145769924">+971 4 5769924</a>
                            </div>
                            <div className="contact-item">
                                <i className="fas fa-envelope"></i>
                                <a href="mailto:info@olivebranchdubai.com">info@olivebranchdubai.com</a>
                            </div>
                            <div className="contact-item">
                                <i className="fas fa-map-marker-alt"></i>
                                <span>1104 Prime Tower, Business Bay, Dubai, UAE</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1 col-12 p-0"></div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 d-flex justify-content-center">
                        <div className="bg-contact">
                            <div className="container">
                                <div className="row align-items-center position-relative px-2 py-35 pt-4">
                                    <div className="col-12">
                                        <div className="row w-100">
                                            <div className={'col-md-12'}>
                                                <div
                                                    className={` ${isActive ? " alert-box d-block" : "alert-box d-none"}`}>
                                                    <div className="alert alert-success alert-dismissible fade show"
                                                         role="alert">
                                                        Successfully Done
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <form className="z-index-999"
                                              onSubmit={formik.handleSubmit}
                                              noValidate>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                    <label className="form-label">Name</label>
                                                    <input type="text"  {...formik.getFieldProps("name")}
                                                           className={clsx(
                                                               "form-control",
                                                               {
                                                                   "is-invalid":
                                                                       formik.touched.name && formik.errors.name,
                                                               },
                                                               {
                                                                   "is-valid":
                                                                       formik.touched.name && !formik.errors.name,
                                                               })} name="name"/>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                    <label className="form-label">Email</label>
                                                    <input type="email"
                                                           {...formik.getFieldProps("email")}
                                                           className={clsx(
                                                               "form-control",
                                                               {
                                                                   "is-invalid":
                                                                       formik.touched.email && formik.errors.email,
                                                               },
                                                               {
                                                                   "is-valid":
                                                                       formik.touched.email && !formik.errors.email,
                                                               })} name="email"/>
                                                </div>
                                                {subjects && subjects.length > 0 &&
                                                    <div className={'col-md-12 mt-3'}>
                                                        <label className="form-label">Subject</label>
                                                        <Select

                                                            className={clsx("h-none w-100",
                                                                {
                                                                    "invalid-select":
                                                                        formik.touched.subject && formik.errors.subject
                                                                },
                                                                {
                                                                    "valid-select":
                                                                        formik.touched.subject && !formik.errors.subject
                                                                },
                                                            )}
                                                            closeMenuOnSelect={true}
                                                            placeholder={"Select Subject"}
                                                            options={subjects.map((e: {
                                                                label: any;
                                                                value: any
                                                            }) => ({label: e.label, value: e.value}))}
                                                            isSearchable={true}
                                                            onChange={(selectedOption: any) => {
                                                                formik.setFieldValue('subject', selectedOption.value);
                                                                setSelectedSubject(selectedOption.value);
                                                            }}
                                                            value={subjects.find(subject => subject.value === selectedSubject.value)}


                                                        />
                                                    </div>
                                                }
                                                <div className="col-md-12 mt-3">
                                                    <label className="form-label">Message</label>
                                                    <textarea    {...formik.getFieldProps("message")}
                                                                 className={clsx(
                                                                     "form-control h-150",
                                                                     {
                                                                         "is-invalid":
                                                                             formik.touched.message && formik.errors.message,
                                                                     },
                                                                     {
                                                                         "is-valid":
                                                                             formik.touched.message && !formik.errors.message,
                                                                     })} name="message"/>
                                                </div>

                                                {formik.touched.message && formik.errors.message && (
                                                    <div className="fv-plugins-message-container">
                                                        <span role="alert">{formik.errors.message}</span>
                                                    </div>
                                                )}

                                                <div className="col-lg-4 col-6 mt-3">

                                                    <button className="btn btn-black p-40 w-100"
                                                            disabled={formik.isSubmitting}
                                                            type="submit">{!loading && (
                                                        <span className="indicator-label">
                                  Submit
                                </span>
                                                    )}
                                                        {loading && (
                                                            <span
                                                                className="indicator-progress"
                                                                style={{display: "block"}}
                                                            >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                                        )}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
